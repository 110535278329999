@import "../common/base.scss";

.container {
  max-height: calc(100vh - 152px);
  overflow-y: auto;
  overflow-x: hidden;
  background: $white;
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  @include drop-shadow-level-4;

  .header {
    background: $navy-blue-10;
    border-radius: 8px 8px 0 0;
    color: $navy-blue-80;
    font-size: 16px;
    line-height: 20px;
    padding: 16px 24px 13px 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    @include drop-shadow-level-1;

    button {
      width: 16px;
      height: 16px;
      @include filter-navy-blue-50;

      img {
        height: 16px;
        width: 16px;
      }
    }
  }

  .content {
    margin-left: 25px;
    margin-right: 25px;
    padding-top: 25px;
  }
}

.container {
  max-width: 1152px;
}

@media (max-width: 1200px) {
  .container {
    max-width: 976px;
  }
}

@media (max-width: $desktop) {
  .container {
    max-width: 720px;
  }
}

@media (max-width: $largeTablet) {
  .container {
    max-width: 600px;
  }
}

@media (max-width: $tablet) {
  .container {
    max-width: 500px;
  }
}
@media (max-width: $mobile) {
  .container {
    max-width: 350px;
    left: 35%;
  }
}
