@import "reset";
@import "fonts";
@import "colors";

body {
  font-family: "Proxima Nova", "Open Sans", sans-serif;
  font-size: 1em;
  color: #002050;
  background-color: $light-grey-100;
}

a {
  color: $sidekick-dark-blue-100;
  &:visited {
    color: $sidekick-dark-blue-100;
  }
}
