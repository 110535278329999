@import "./common/base";

$blendmode: hard-light;
$size: 100px;
$thickness: 10px;

$blue: $sidekick-dark-blue-100;
$green: $neon-green-100;
$red: $neon-pink-100;
$yellow: $mustard-yellow-100;
$bgcolor: transparent;

$timing1: 2s;
$timing2: 1.8s;
$delay: $timing1/4;

.verticalCenteredBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  &:after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
  .content {
    box-sizing: border-box;
    animation: fadein 0.5s 0s ease-out forwards;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: 0;
    opacity: 0;
  }
}

.loaderLineMask {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $size/2;
  height: $size;
  transform-origin: $size/2 $size/2;
  margin-left: -$size/2;
  margin-top: -$size/2;
  overflow: hidden;
  -webkit-mask-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  mix-blend-mode: $blendmode;
  opacity: 0.8;
  .loaderLine {
    width: $size;
    height: $size;
    border-radius: 50%;
  }

  &.one {
    animation: rotate $timing1 linear infinite;
    .loaderLine {
      box-shadow: inset 0 0 0 $thickness $green;
    }
  }

  &.two {
    animation: rotate $timing2 linear $delay infinite;
    .loaderLine {
      box-shadow: inset 0 0 0 $thickness $red;
    }
  }

  &.three {
    animation: rotate $timing1 linear $delay * 2 infinite;
    .loaderLine {
      box-shadow: inset 0 0 0 $thickness $yellow;
    }
  }

  &.four {
    animation: rotate $timing2 linear ($delay * 3) - (($timing1 - $timing2) / 2)
      infinite;
    .loaderLine {
      box-shadow: inset 0 0 0 $thickness $blue;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
