@import "base";

.form {
  &--block {
    .inputContainer {
      display: flex;
    }
  }

  &__horizontal-section {
    display: flex;
    flex-direction: row;

    input[type="radio"]:not(:first-of-type) {
      margin-left: 24px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;
  }
}

.input {
  flex-grow: 1;
  flex-shrink: 1;
  border: 1px solid $navy-blue-20;
  border-radius: 4px;
  font-weight: 400;
  line-height: 38px;
  height: 38px;
  padding: 0 14px;
  background: $white;
  font-size: 16px;
  color: $navy-blue-80;

  &.error {
    border-color: $red-100;
  }

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }

  &.textarea {
    flex: none;
    line-height: 24px;
    padding: 8px 15px;

    &.input-lg {
      padding: 12px 16px;
    }

    &.textarea-expandable {
      height: 24px;
      overflow-y: auto;
      resize: none;
    }
  }

  &::placeholder {
    color: $navy-blue-40;
  }

  &:focus {
    outline: $sidekick-dark-blue-80 auto 1px;
  }

  &--block {
    display: block;
    margin-bottom: 24px;
  }

  &--invalid {
    border-color: $neon-pink-100;
  }

  &--block.input--invalid {
    margin-bottom: 3px;
  }

  &__error-msg,
  &__error-block {
    color: $neon-pink-100;
    font-size: 14px;
    line-height: 17px;
  }

  &__error-msg {
    margin-bottom: 4px;
  }

  &--font-sm {
    font-size: 14px;
  }

  &-sm {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }

  &-lg {
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
  }
}

.search {
  position: relative;
  display: flex;
  background-color: $white;

  .search-icon {
    height: 16px;
    width: 16px;
    position: absolute;
    left: 16px;
    top: 12px;
    @include filter-navy-blue-40;
  }

  .search-input {
    height: 38px;
    padding-left: 40px;
    font-size: 14px;
    line-height: 18px;
    color: $navy-blue-80;
  }

  &.search-sm {
    .search-icon {
      left: 24px;
    }

    .search-input {
      height: 30px;
      padding-left: 32px;
    }
  }
}

.select {
  position: relative;
  display: flex;
  padding-right: 40px;
  height: 40px;
  background: $white url("../assets/select-arrow.svg") no-repeat right 12px
    center / 15px;
}

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  color: $navy-blue-100;

  input {
    height: 15px;
    width: 15px;
    border: 1px solid $navy-blue-20;
    border-radius: 1px;
    flex-shrink: 0;
    background: $white;
  }

  &:hover input {
    background: $sidekick-dark-blue-20;
    border-color: $sidekick-dark-blue-20;
  }

  input:checked {
    background: $sidekick-dark-blue-100;
    border-color: $sidekick-dark-blue-100;
  }

  &:hover input:checked {
    background: $sidekick-dark-blue-120;
    border-color: $sidekick-dark-blue-120;
  }

  .check-icon {
    height: 9px;
    width: 9px;
    position: absolute;
    left: 7px;
    @include filter-white;
  }

  &-lg {
    font-size: 16px;

    input {
      height: 24px;
      width: 24px;
      border-radius: 4px;
      margin-right: 12px;
    }

    .check-icon {
      height: 14px;
      width: 14px;
      left: 9px;
    }
  }
}

.label {
  font-weight: 600;
  line-height: 19px;

  &--block {
    display: block;
    margin-bottom: 8px;
  }
}

input[type="radio"] {
  appearance: radio;
}
