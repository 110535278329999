$sidekick-dark-blue-140: #003099;
$sidekick-dark-blue-120: #0040cc;
$sidekick-dark-blue-100: #0050ff; // primary color
$sidekick-dark-blue-80: #3373ff;
$sidekick-dark-blue-60: #6696ff;
$sidekick-dark-blue-40: #99b9ff;
$sidekick-dark-blue-20: #ccdcff;

$sidekick-aqua-100: #20dfff; // primary color

$neon-pink-100: #ff00df;

$neon-green-160: #006646;
$neon-green-140: #009969;
$neon-green-120: #00cc8c;
$neon-green-100: #00ffaf;
$neon-green-20: #ccffef;
$neon-green-5: #f2fffb;

$mustard-yellow-160: #66530d;
$mustard-yellow-120: #cca61a;
$mustard-yellow-100: #ffcf20;
$mustard-yellow-20: #fff5d2;

$light-blue-160: #004066;
$light-blue-140: #005f99;
$light-blue-120: #007fcc;
$light-blue-100: #009fff;
$light-blue-80: #33b2ff;
$light-blue-20: #ccecff;
$light-blue-10: #e5f5ff;
$light-blue-5: #f2faff;

$navy-blue-100: #002050;
$navy-blue-80: #334d73;
$navy-blue-60: #667996;
$navy-blue-50: #7f8fa7;
$navy-blue-40: #99a6b9;
$navy-blue-20: #ccd2dc;
$navy-blue-10: #e5e8ed;
$navy-blue-5: #f2f4f6;

$light-grey-100: #f5f5fa;

$red-140: #990020;
$red-100: #ff0040;
$red-20: #ffccd9;
$red-5: #fff2f5;

$white: #fff;
$white-80: rgba(255, 255, 255, 0.8);
$white-60: rgba(255, 255, 255, 0.6);
