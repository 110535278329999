@import "base";

.Avatar {
  background-color: lightgrey;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-shrink: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;

  &.xs {
    width: 16px;
    height: 16px;
    .tooltip {
      bottom: 24px;
    }
  }

  &.sm {
    width: 24px;
    height: 24px;
    .tooltip {
      bottom: 32px;
    }
  }

  &.md {
    width: 40px;
    height: 40px;
    .tooltip {
      bottom: 48px;
    }
  }

  &.lg {
    width: 56px;
    height: 56px;
    .tooltip {
      bottom: 56px;
    }
  }

  &.xl {
    width: 88px;
    height: 88px;
    .tooltip {
      bottom: 88px;
    }
  }

  &.circle,
  &.circular {
    border-radius: 50%;
  }

  &.rounded {
    border-radius: 8px;
  }

  &.square {
    border-radius: 0;
  }

  &.shadow {
    @include drop-shadow-level-2;
  }

  &.transparent {
    background-color: transparent;
  }
}

.tooltip {
  position: absolute;
  background: $white;
  padding: 12px;
  border-radius: 8px;
  z-index: 2;
  @include drop-shadow-level-2;
  white-space: nowrap;
}
