@import "base";

.CustomSelect {
  cursor: pointer;

  &.disabled {
    cursor: default;
    opacity: 0.7;
  }

  span {
    overflow: hidden;
  }

  select {
    display: none;
  }

  ul {
    position: absolute;
    border-radius: 4px;
    z-index: 1;
    background: $white;
    max-height: 214px;
    height: fit-content;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50px;
    left: 0;
    @include drop-shadow-level-3;

    li {
      padding: 0 12px 11px 12px;
      cursor: pointer;
      line-height: initial;
      overflow: hidden;
      &:first-child {
        padding-top: 11px;
      }

      &:hover {
        background: $navy-blue-5;
      }

      .separator {
        margin: 0 -4px 11px -4px;
        height: 1px;
        background: $navy-blue-10;
      }
    }
  }
}

.carousel {
  $offset: -60px;
  &:hover {
    width: calc(100% - #{$offset});
    transition: transform 4s linear 1s;
    transform: translateX($offset);
  }
}
