@import "../common/base";

.NavProfile,
.NavNotificationsDropdown {
  background-color: $white;
  @include drop-shadow-level-3;
  border-radius: 8px;
  position: relative;

  &__Notch {
    position: absolute;
    background-color: $white;
    transform: rotate(45deg);
    top: -5px;
    left: 100px;
    width: 10px;
    height: 10px;
  }
}
