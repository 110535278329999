@import "NavDropdown.module";

.NavProfile {
  width: 260px;

  &__NavItem {
    border-bottom: 1px solid #e5e8ed;

    > a,
    button {
      display: flex;
      padding: 16px;

      > span {
        color: $navy-blue-100;
        flex: 2 0 auto;
        text-align: center;
      }
    }
    > button {
      width: 100%;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__Notch {
    left: auto;
    right: 48px;
  }

  &__Icon {
    height: 24px;
    width: 24px;
    margin-right: 18px;
    @include filter-sidekick-dark-blue-100;
  }
  &__User {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__Avatar {
      margin-right: 8px;
    }
  }
  &__ProfileDropdown {
    position: absolute;
    top: 56px;
    right: -11px;
  }
}
