@import "../../../common/base";
.editProvider {
  button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.success {
  height: 30px;
}

.error,
.success {
  margin-right: 24px;
}
.error {
  color: $neon-pink-100;
}
.success {
  color: $neon-green-140;
}
.selectWrapper {
  width: 350px;
  margin-bottom: 24px;
}
