@import "base";

button {
  cursor: pointer;
}

@mixin btn {
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 46px;
  padding: 0 32px;
  color: $white;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: visible;

  img {
    position: absolute;
    left: 12px;
    top: 6px;
    height: 24px;
  }

  &:disabled {
    cursor: auto;
  }

  &.btn-sm {
    font-size: 16px;
    line-height: 38px;
  }

  &.btn-circle {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      position: static;
      height: 16px;
    }
  }
}

.btn-primary {
  @include btn;
  background: $sidekick-dark-blue-100;
  img {
    @include filter-white;
  }

  &:hover {
    background: $sidekick-dark-blue-80;
  }

  &:active {
    background: $sidekick-dark-blue-140;
  }

  &:disabled {
    background: $sidekick-dark-blue-40;
  }
}

.btn-secondary {
  @include btn;
  background: transparent;
  color: $sidekick-dark-blue-100;
  border: 1px solid $sidekick-dark-blue-100;
  img {
    @include filter-sidekick-dark-blue-100;
  }

  &:hover {
    border-color: $sidekick-dark-blue-100;
    background: $sidekick-dark-blue-100;
    color: $white;
    img {
      @include filter-white;
    }
  }

  &:active {
    border-color: $sidekick-dark-blue-140;
    background: $sidekick-dark-blue-140;
    color: $white;
  }

  &:disabled {
    background: transparent;
    border-color: $sidekick-dark-blue-40;
    color: $sidekick-dark-blue-40;
    img {
      @include filter-sidekick-dark-blue-40;
    }
  }
}

.btn-primary-green {
  @include btn;
  background: $neon-green-140;

  img {
    @include filter-white;
  }

  &:not(:disabled):hover {
    background: $neon-green-120;
  }

  &:active {
    background: $neon-green-160;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-secondary-green {
  @include btn;
  background: transparent;
  color: $neon-green-140;
  border: 1px solid $neon-green-140;

  &:not(:disabled):hover {
    background: $neon-green-140;
    border-color: $neon-green-140;
    color: $white;
  }

  &:active {
    background: $neon-green-160;
    border-color: $neon-green-160;
    color: $white;
  }

  &:disabled {
    background: transparent;
    opacity: 0.5;
  }
}

.btn-primary-grey {
  @include btn;
  background: $navy-blue-80;
  img {
    @include filter-white;
  }

  &:hover {
    background: $navy-blue-60;
  }

  &:active {
    background: $navy-blue-100;
  }

  &:disabled {
    background: $navy-blue-20;
  }
}

.btn-secondary-grey {
  @include btn;
  background: transparent;
  color: $navy-blue-80;
  border: 1px solid $navy-blue-80;
  img {
    @include filter-navy-blue-80;
  }

  &:hover {
    border-color: $navy-blue-80;
    background: $navy-blue-80;
    color: $white;
    img {
      @include filter-white;
    }
  }

  &:active {
    border-color: $navy-blue-100;
    background: $navy-blue-100;
    color: $white;
  }

  &:disabled {
    background: transparent;
    border-color: $navy-blue-20;
    color: $navy-blue-20;
  }
}

.btn-tertiary-lightgrey {
  @include btn;
  background: $light-grey-100;
  color: $navy-blue-100;
  font-weight: 400;
  display: flex;
  padding: 0 16px;
  box-shadow: none;

  img {
    position: static;
    margin-right: 8px;
    @include filter-light-blue-100;
  }

  &:hover {
    background: $navy-blue-10;
  }

  &:active {
    background: $navy-blue-20;
  }

  &:disabled {
    opacity: 0.8;
    background: $light-grey-100;
  }
}
