@mixin filter-light-blue-100 {
  filter: invert(47%) sepia(89%) saturate(2994%) hue-rotate(179deg)
    brightness(102%) contrast(108%);
}

@mixin filter-sidekick-dark-blue-100 {
  filter: invert(16%) sepia(75%) saturate(5075%) hue-rotate(224deg)
    brightness(104%) contrast(105%);
}

@mixin filter-sidekick-dark-blue-80 {
  filter: invert(44%) sepia(83%) saturate(4755%) hue-rotate(214deg)
    brightness(104%) contrast(102%);
}

@mixin filter-sidekick-dark-blue-60 {
  filter: invert(55%) sepia(92%) saturate(2096%) hue-rotate(199deg)
    brightness(101%) contrast(101%);
}

@mixin filter-sidekick-dark-blue-40 {
  filter: invert(88%) sepia(29%) saturate(5906%) hue-rotate(187deg)
    brightness(101%) contrast(102%);
}

@mixin filter-navy-blue-100 {
  filter: invert(12%) sepia(37%) saturate(3148%) hue-rotate(197deg)
    brightness(95%) contrast(111%);
}

@mixin filter-navy-blue-80 {
  filter: invert(27%) sepia(12%) saturate(2253%) hue-rotate(177deg)
    brightness(94%) contrast(89%);
}

@mixin filter-navy-blue-60 {
  filter: invert(50%) sepia(35%) saturate(331%) hue-rotate(177deg)
    brightness(87%) contrast(90%);
}

@mixin filter-navy-blue-50 {
  filter: invert(55%) sepia(39%) saturate(208%) hue-rotate(177deg)
    brightness(94%) contrast(88%);
}

@mixin filter-navy-blue-40 {
  filter: invert(73%) sepia(8%) saturate(658%) hue-rotate(176deg)
    brightness(90%) contrast(88%);
}

@mixin filter-navy-blue-20 {
  filter: invert(88%) sepia(8%) saturate(251%) hue-rotate(179deg)
    brightness(97%) contrast(87%);
}

@mixin filter-neon-green-120 {
  filter: invert(77%) sepia(66%) saturate(4582%) hue-rotate(117deg)
    brightness(93%) contrast(102%);
}

@mixin filter-neon-pink-100 {
  filter: invert(24%) sepia(77%) saturate(4563%) hue-rotate(295deg)
    brightness(98%) contrast(134%);
}

@mixin filter-white {
  filter: brightness(0) invert(100%);
}

@mixin filter-red-100 {
  filter: invert(15%) sepia(49%) saturate(6744%) hue-rotate(335deg)
    brightness(108%) contrast(119%);
}

@mixin filter-mustard-yellow-120 {
  filter: invert(55%) sepia(45%) saturate(702%) hue-rotate(10deg)
    brightness(107%) contrast(97%);
}
