@font-face {
  font-family: 'Proxima Nova';
  src: url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-bold-webfont.woff2') format('woff2'),
    url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-semibold-webfont.woff2') format('woff2'),
    url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-regular-webfont.woff2') format('woff2'),
    url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-regularit-webfont.woff2') format('woff2'),
    url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-regularit-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-light-webfont.woff2') format('woff2'),
    url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-thin-webfont.woff2') format('woff2'),
    url('https://assets.sidekickhealth.com/fonts/ProximaNova/proximanova-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}