@import "../common/base";

.NavHeader {
  height: 32px;
  @include drop-shadow-level-2;
  background-color: $white;
  padding: 12px 24px;
  position: relative;
  justify-content: center;
  z-index: 4;

  .HeaderContent {
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    max-width: 1392px;
    position: relative;

    &__Logo {
      height: 32px;
    }

    &__Right {
      display: flex;
      align-items: center;
    }

    &__Notifications,
    &__Messages {
      position: relative;
      margin-right: 36px;
      display: flex;

      &__Notification {
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }

    &__Icon {
      height: 24px;
      width: 24px;
      @include filter-sidekick-dark-blue-100;
    }
  }
}
